import styled from "styled-components"

const Card = styled.div`
  background-color: var(--white);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  transition: var(--transitionShort);

  &:before {
    background: linear-gradient(
      180.18deg,
      rgba(248, 248, 251, 0) 0.77%,
      rgba(248, 248, 251, 1) 94.61%
    );
    bottom: 0;
    content: "";
    height: 50%;
    left: 0;
    position: absolute;
    transition: var(--transitionShort);
    width: 100%;
    z-index: var(--zBack);
  }

  &:hover {
    box-shadow: 0 8px 40px 0 rgba(210, 212, 227, 0.2);
    transform: translateY(-0.5rem);

    &:before {
      opacity: 0;
    }
  }

  > * {
    position: relative;
    z-index: var(--zBase);
  }
`

export default Card
