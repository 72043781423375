import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import Question from "@molecules/cards/question"
import { media } from "@styles/breakpoints"

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query fetchQuestions {
      datoCmsPricing {
        questions {
          title
          content
        }
      }
    }
  `)

  return (
    <Section xl lgTop>
      <Container>
        <Flex alignStart>
          <Title third>
            <Heading html="h1" level="h2">
              Frequently Asked Questions
            </Heading>
            <Paragraph level="lg">
              Need more help?
              <br /> Check out our&nbsp;
              <a href="http://help.moonclerk.com/">Help Center.</a>
            </Paragraph>
          </Title>
          <Column twoThirds>
            {data.datoCmsPricing.questions.map((question, index) => (
              <Question key={index} question={question} />
            ))}
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default FAQ

const Title = styled(Column)`
  margin-bottom: var(--spacing--xs);

  ${media.sm`
    left: 0;
    position: sticky;
    top: 8rem;
  `}
`
