import React from "react"
import styled from "styled-components"

import FeeIcon from "@assets/icons/fees.svg"

import { Section, Container, Flex } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"

const Fees = () => {
  return (
    <Section md>
      <CardContainer aqua containerSmall>
        <Content alignCenter column>
          <Icon />
          <Heading html="h4" level="h4" center>
            You’ll also pay Stripe’s processing fees
          </Heading>
          <Paragraph level="md" center>
            MoonClerk partners with payment processor Stripe to help you accept
            payments immediately & securely.
          </Paragraph>
        </Content>
        <Link href="https://stripe.com/pricing" target="_blank">View Stripe's Per-Transaction Fees</Link>
      </CardContainer>
    </Section>
  )
}

export default Fees

const CardContainer = styled(Container)`
  border-radius: var(--borderRadius);
`

const Content = styled(Flex)`
  padding: var(--spacing--sm) var(--spacing--sm) var(--spacing--xs);
  justify-content: center;
`

const Icon = styled(FeeIcon)`
  margin-bottom: 1.5rem;
`

const Link = styled.a`
  background-color: var(--aqua--20);
  border: 0 0 var(--borderRadius) var(--borderRadius);
  color: var(--aqua--80);
  display: block;
  letter-spacing: 2px;
  padding: var(--spacing--xxs);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: var(--aqua--40);
  }
`
