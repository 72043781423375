import React from "react"

import { Heading } from "@atoms/typography"
import { Volume, Item, Title, TopRow, BottomRow } from "@organisms/pricing"

const LowVolume = ({ pricing }) => {
  return (
    <Volume className={pricing === false ? "-active" : "-inactive"}>
      <TopRow>
        <Title>
          <Heading html="h3" level="sh" strong lightGray noMargin right>
            Monthly
            <br />
            Price:
          </Heading>
        </Title>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $18
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $35
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $60
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $90
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $130
          </Heading>
        </Item>
      </TopRow>
      <BottomRow>
        <Title>
          <Heading html="h3" level="sh" strong lightGray noMargin right>
            Monthly
            <br />
            Volume:
          </Heading>
        </Title>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $0 - $2,000
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $2,000 - $4,000
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $4,000 - $7,500
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $7,500 - $12,500
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $12,500 - $20,000
          </Heading>
        </Item>
      </BottomRow>
    </Volume>
  )
}

export default LowVolume
