import React from "react"

import { Heading } from "@atoms/typography"
import { Volume, Item, Title, TopRow, BottomRow } from "@organisms/pricing"

const HighVolume = ({ pricing }) => {
  return (
    <Volume className={pricing === true ? "-active" : "-inactive"}>
      <TopRow>
        <Title>
          <Heading html="h3" level="sh" strong lightGray noMargin right>
            Monthly
            <br />
            Price:
          </Heading>
        </Title>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $175
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $230
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $310
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $415
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="h3" noMargin center>
            $550
          </Heading>
        </Item>
      </TopRow>
      <BottomRow>
        <Title>
          <Heading html="h3" level="sh" strong lightGray noMargin right>
            Monthly
            <br />
            Volume:
          </Heading>
        </Title>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $20,000 - $30,000
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $30,000 - $45,000
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $45,000 - $65,000
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $65,000 - $100,000
          </Heading>
        </Item>
        <Item>
          <Heading html="h3" level="ssh" strong lightGray noMargin center>
            $100,000 - $150,000
          </Heading>
        </Item>
      </BottomRow>
    </Volume>
  )
}

export default HighVolume
