import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Link from "@atoms/link"
import { Heading, Paragraph } from "@atoms/typography"
import Card from "@molecules/cards/card"

const CardExtension = ({ extension }) => {
  return (
    <Link to={`/${extension.slug}`}>
      <ExtensionCard>
        <div>
          <Icon image={extension.icon.gatsbyImageData} alt={extension.title} />
          <Heading html="h6" level="sh" gray strong>
            {extension.title}
          </Heading>
          <Heading html="h3" level="h3">
            {extension.heading}
          </Heading>
          <Paragraph level="md">{extension.description}</Paragraph>
        </div>
        <div>
          <Price>
            <Heading html="h3" level="h3" gray>
              {extension.price}
            </Heading>
          </Price>
          <ExtensionLink>
            Learn More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
              viewBox="0 0 10 10"
            >
              <path
                fill="#0AB7A8"
                d="M3.438 0l-1.25 1.25L5.938 5l-3.75 3.75L3.438 10l5-5-5-5z"
              ></path>
            </svg>
          </ExtensionLink>
        </div>
      </ExtensionCard>
    </Link>
  )
}

export default CardExtension

const Icon = styled(GatsbyImage)`
  background-color: var(--gray--40);
  border-radius: 40px;
  height: 64px;
  margin-bottom: 2rem;
  width: 64px;
`

const Price = styled.div`
  align-items: flex-end;
  display: flex;
  margin-bottom: var(--spacing--sm);
  margin-top: var(--spacing--sm);

  h3 {
    margin-bottom: 0;
    margin-right: 0.3rem;
  }

  h6 {
    letter-spacing: 0.5px;
    margin-bottom: 0.25rem;
  }
`

const ExtensionLink = styled.h6`
  align-items: center;
  color: var(--colorPrimary);
  display: flex;
  flex-direction: row;
  font-size: 0.875rem !important;
  letter-spacing: 1.5px;
  margin: 0.25rem 0;
  text-transform: uppercase;
  transition: var(--transitionBase);

  svg {
    margin-left: 0.5rem;
    transition: var(--transitionBase);

    path {
      transition: var(--transitionBase);
    }
  }
`

const ExtensionCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 3rem var(--spacing--xs);

  &:hover {
    ${ExtensionLink} {
      color: var(--aqua--80);

      svg {
        transform: translateX(-0.25rem);

        path {
          fill: var(--aqua--80);
        }
      }
    }
  }
`
