import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

import Button from "@atoms/button"
import { Container } from "@atoms/layout"
import { Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"
import LowVolume from "@molecules/pricing/lowVolume"
import HighVolume from "@molecules/pricing/highVolume"

const VolumePricing = () => {
  const line = useRef()
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(DrawSVGPlugin)
    }

    gsap.defaults({
      ease: "sine.inOut", 
    });

    gsap.set(line.current, {
      drawSVG: "0% 0%",
    })
  }, [])

  const drawLine = () => {
    gsap.fromTo(
      line.current,
      { drawSVG: "100% 100%", duration: 1, delay: 0.25 },
      { drawSVG: "100% 0%", duration: 1}
    )
  }

  const [pricing, setPricing] = useState(false)
  const toggleVolume = event => {
    event.preventDefault()
    setPricing(!pricing)
    drawLine()
  }

  const openIntercom = () => {
    window.Intercom('show');
  }

  return (
    <PricingContainer>
      <LowVolume pricing={pricing} />
      <HighVolume pricing={pricing} />
      <Info>
        <MoreVolume className={pricing === true ? "-active" : ""}>
           <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="49"
            fill="none"
            viewBox="0 0 75 49"
          >
            <path
              ref={line}
              stroke="#203543"
              d="M0 48c27.5 0 26-25 15.5-25-5.953 0-6 12 11.5 12S65 22 74.5.5"
            ></path>
          </svg>

          <Paragraph level="sm" noMargin>
            Need more volume? <Intercom onClick={openIntercom} onKeyDown={openIntercom} >Get in touch.</Intercom>
          </Paragraph>
        </MoreVolume>

        <VolumeButton
          onClick={toggleVolume}
          onKeyDown={toggleVolume}
          to="/pricing"
          className={pricing === true ? "-high" : "-typical"}
          xs
          border
          tertiary
        >
          {pricing === true ? "Typical" : "High"} Volume Rates
          <svg width="10" height="10" fill="none" viewBox="0 0 10 10">
            <path
              fill="#6B7783"
              d="M3.438 0L2.5.938 6.563 5 2.5 9.063l.938.937 5-5-5-5z"
            ></path>
          </svg>
        </VolumeButton>
      </Info>
    </PricingContainer>
  )
}

export default VolumePricing

const PricingContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;

  ${media.sm`
    flex-direction: row;
  `}
`

const Intercom = styled.span`
  color: var(--colorPrimary);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--headingWeight);
  margin: 0;
  text-decoration: underline;
`

const MoreVolume = styled.div`
  align-items: center;
  display: none;
  flex-direction: column;
  padding: 0 0 2rem 0;

  ${media.sm`
    padding: 0;
    flex-direction: row-reverse;
    position: absolute;
    right: 0;
    top: 0;
  `}

  svg {
    display: none;
    margin-left: 0.75rem;
    transform: translateY(-1.25rem);
    
    ${media.sm`
      display: inherit;
    `}
  }

  &.-active {
    display: flex;
  }
`

const VolumeButton = styled(Button)`
  align-items: center;
  display: inline-flex;
  justify-content: center;

  &.-typical {
    flex-direction: row;

    svg {
      margin-left: 0.5rem;
    }
  }

  &.-high {
    flex-direction: row-reverse;

    svg {
      margin-right: 0.5rem;
      transform: rotate(180deg);
    }
  }
`

export const Volume = styled.div`
  display: none;
  flex-direction: row;
  padding-bottom: 1.5rem;
  width: 100%;

  ${media.sm`
  flex-direction: column;
  `}

  &.-active {
    display: flex;
  }
`

export const Item = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  padding: 1rem 0.5rem;
  text-align: center;
  width: 100%;

  ${media.sm`
    height: auto;
    flex: 1;
  `}
`

export const Title = styled(Item)`
  display: none;
  align-self: center;
  padding-right: 2rem;

  ${media.sm`
    display: block;
    flex: 0 0 125px;
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.sm`
    align-items: stretch;
    flex-direction: row;
    width: 100%;
  `}
`

export const TopRow = styled(Row)`
  width: 40%;

  ${Title}, ${Item} {
    justify-content: flex-end;
    padding-right: 2rem;
  }

  ${Item} {
    &:nth-of-type(2) {
      border-right: 8px solid var(--aqua--40);
    }
    &:nth-of-type(3) {
      border-right: 12px solid var(--aqua--40);
    }
    &:nth-of-type(4) {
      border-right: 16px solid var(--aqua--40);
    }
    &:nth-of-type(5) {
      border-right: 20px solid var(--aqua--40);
    }
    &:nth-of-type(6) {
      border-right: 24px solid var(--aqua--40);
    }
  }

  ${media.sm`
    width: 100%;

    ${Title}, ${Item} {
    justify-content: center;
  }
  
  ${Item} {
    &:nth-of-type(2) {
      border: none;
      border-bottom: 8px solid var(--aqua--40);
    }
    &:nth-of-type(3) {
      border: none;
      border-bottom: 12px solid var(--aqua--40);
    }
    &:nth-of-type(4) {
      border: none;
      border-bottom: 16px solid var(--aqua--40);
    }
    &:nth-of-type(5) {
      border: none;
      border-bottom: 20px solid var(--aqua--40);
    }
    &:nth-of-type(6) {
      border: none;
      border-bottom: 24px solid var(--aqua--40);
    }
  }
  `}
`

export const BottomRow = styled(Row)`
  width: 60%;

  ${Item} {
    justify-content: flex-start;
    padding-left: 2rem;
  }

  ${media.sm`
    width: 100%;

    ${Item} {
      justify-content: center;
      padding-left: 0;

    }
  `}
`
