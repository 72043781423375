import React from "react"
import styled from "styled-components"

import { Heading, Paragraph } from "@atoms/typography"

const Question = ({ question }) => {
  return (
    <Card>
      <Heading html="h4" level="h4">
        {question.title}
      </Heading>
      <Paragraph level="md">{question.content}</Paragraph>
    </Card>
  )
}

export default Question

const Card = styled.div`
  display: block;
  border-bottom: var(--borderPrimary);
  padding-bottom: var(--spacing--xxs);
  margin-bottom: var(--spacing--xs);

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`
