import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Heading } from "@atoms/typography"
import { Section, Container, Flex, Column } from "@atoms/layout"
import CardExtension from "@molecules/cards/extension"

const SectionExtensions = () => {
  const data = useStaticQuery(graphql`
    query fetchExtensions {
      datoCmsGlobal {
        extensionSubheading
        extensionHeading
      }
      allDatoCmsExtension(sort: { fields: position, order: ASC }) {
        extensions: nodes {
          slug
          icon {
            alt
            title
            gatsbyImageData(
              width: 160
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
          title
          heading
          description
          price
        }
      }
    }
  `)

  const { extensions } = data.allDatoCmsExtension

  return (
    <Section light md mdTop>
      <Container sm paragraph containerSmall>
        <Heading html="h6" level="sh" strong center lightGray>
          {data.datoCmsGlobal.extensionSubheading}
        </Heading>
        <Heading html="h1" level="h2" center>
          {data.datoCmsGlobal.extensionHeading}
        </Heading>
      </Container>
      <Container flex>
        <Flex stretch>
          {extensions.map((extension, index) => (
            <Column third key={index}>
              <CardExtension extension={extension} />
            </Column>
          ))}
        </Flex>
      </Container>
    </Section>
  )
}

export default SectionExtensions
