import React from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { graphql } from "gatsby"

import { Section, Container } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import Fees from "@molecules/fees"
import SectionExtensions from "@organisms/sections/extensions"
import VolumePricing from "@organisms/pricing"
import FAQ from "@organisms/sections/faq"
import Layout from "@utils/layout"

const Pricing = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsPricing.seoMetaTags}/>
      <div data-datocms-noindex>
        <Section xlTop md>
          <Container xs>
            <Heading html="h1" level="h1" center>
              {data.datoCmsPricing.heading}
            </Heading>
            <Paragraph level="md" center>
              {data.datoCmsPricing.subheading}
            </Paragraph>
          </Container>
          <VolumePricing />
        </Section>
        <Fees />
        <SectionExtensions />
        <FAQ />
      </div>
    </Layout>
  )
}

export default Pricing

export const pricingPageQuery = graphql`
  {
    datoCmsPricing {
      heading
      subheading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
